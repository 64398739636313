import React, { useEffect, useState } from "react";
import { stateOptions, termOptions } from "../utils/options";
import { Controller, useForm } from "react-hook-form";
import { FaExclamationCircle } from "react-icons/fa";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "../utils/validationSchema";
import DataModal from "./DataModal";
import { useNavigate } from "react-router-dom";
import ImageWithCloseButton from "./ImageWithCloseButton";

const Form = ({
  setExhibitData,
  currentDate,
  fillForm,
  setFileUploads,
  isPartner,
  existingExhibit,
}) => {
  const navigate = useNavigate();
  const [newFileUrls, setNewFileUrls] = useState(existingExhibit.fileUrls);

  const stateSelFunction = (state) => {
    const stateIndex = stateOptions.findIndex(
      (stateOpt) => stateOpt.value === state
    );
    return stateOptions[stateIndex];
  };

  const termSelFunction = (term) => {
    const termIndex = termOptions.findIndex(
      (termOpt) => termOpt.label === term
    );
    return termOptions[termIndex];
  };
  const unFormatDate = (dateString) => {
    const [month, day, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const formatDate = (date) => {
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  const [formState, setFormState] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [overrideState, setOverrideState] = useState(false);
  const errorsArr = [];
  useEffect(() => {
    if (
      formState?.sqftLeased &&
      formState?.desks &&
      formState?.desks > 0 &&
      formState?.sqftLeased / formState.desks > 200 &&
      !overrideState.sqftLeased
    ) {
      errorsArr.push("sqftLeased");
      setErrorOpen(errorsArr);
    }
  }, [formState]);

  useEffect(() => {
    console.log(overrideState);
  }, [overrideState]);

  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i];
      newFile["id"] = Math.random();
      setFileUploads((prevState) => [...prevState, newFile]);
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    let { name } = e.target;
    if (name === "desks") name = "sqftLeased";
    if (overrideState[name]) {
      setOverrideState((prev) => ({ ...prev, [name]: false }));
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...existingExhibit,
      state: stateSelFunction(existingExhibit.state),
      term: termSelFunction(existingExhibit.term),
      startDate: unFormatDate(existingExhibit.startDate),
    },
  });

  const onSubmit = async (data, status) => {
    console.log(data);
    let stateValue = data.state && data.state.value ? data.state.value : "";
    let exhibit = {
      serviceProvider: data.serviceProvider,
      commonName: data.commonName,
      locationAddress: data.locationAddress,
      city: data.city,
      state: stateValue,
      zipcode: data.zipcode,
      sqftLeased: data.sqftLeased,
      term: data.term?.label,
      desks: data.desks,
      propertyType: data.propertyType,
      spaceType: data.spaceType,
      settingType: data.settingType,
      facility: data.facility,
      utility: data.utility,
      amenity: data.amenity,
      startDate: formatDate(data.startDate),
      description: data.description,
      createdDate: currentDate,
      status: status,
    };

    console.log(exhibit);
    setExhibitData(exhibit);

    if (
      !data.facility?.find((item) => item === "cam") &&
      !overrideState["cam"]
    ) {
      errorsArr.push("cam");
    }
    if (
      !data.facility?.find((item) => item === "tax") &&
      !overrideState["tax"]
    ) {
      errorsArr.push("tax");
    }
    if (
      data.amenity?.find((item) => item === "meeting") &&
      data.sqftLeased > 500 &&
      !overrideState["meeting"]
    ) {
      errorsArr.push("meeting");
    }
    if (
      data.amenity?.find((item) => item === "kitchen") &&
      data.sqftLeased > 500 &&
      !overrideState["kitchen"]
    ) {
      errorsArr.push("kitchen");
    }
    if (
      data.amenity?.find((item) => item === "furnished") &&
      data.sqftLeased > 500 &&
      !overrideState["furnished"]
    ) {
      errorsArr.push("furnished");
    }
    if (
      data.amenity?.find((item) => item === "copy") &&
      data.sqftLeased > 500 &&
      !overrideState["copy"]
    ) {
      errorsArr.push("copy");
    }
    if (
      data.amenity?.find((item) => item === "receptionist") &&
      data.sqftLeased > 500 &&
      !overrideState["receptionist"]
    ) {
      errorsArr.push("receptionist");
    }

    if (
      formState?.sqftLeased &&
      formState?.desks &&
      formState?.desks > 0 &&
      formState?.sqftLeased / formState.desks > 200 &&
      !overrideState.sqftLeased
    ) {
      errorsArr.push("sqftLeased");
    }

    // Set the error state only if there are errors
    console.log(errorsArr);
    if (errorsArr.length > 0) {
      setFormState((prev) => ({
        ...prev,
        ...exhibit,
      }));
      setErrorOpen(errorsArr);
    }
    if (errorsArr.length < 1) fillForm(exhibit, "continue");
  };

  return (
    <>
      {errorOpen && (
        <DataModal
          setErrorOpen={setErrorOpen}
          errorOpen={errorOpen}
          formState={formState}
          setFormState={setFormState}
          setOverrideState={setOverrideState}
        />
      )}
      <div className="exhibit-form-container">
        <div>
          <h1 className="new-exhibit-h1">
            Office Lease and Other Real Estate Services
          </h1>
          <h4>(complete one form per locaiton address)</h4>
        </div>

        <form
          className="new-exhibit-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
        >
          <div
            className={
              errors.serviceProvider
                ? "job-form-input-container error"
                : "job-form-input-container"
            }
          >
            {errors.serviceProvider && (
              <p className="error-message">
                {" "}
                <span style={{ marginRight: "5px" }}>
                  <FaExclamationCircle />
                </span>
                {errors.serviceProvider.message}
              </p>
            )}
            <div className="job-form-input">
              <label htmlFor="serviceProvider">
                Service Provider (Lessor/Landlord)
              </label>
              <input
                id="serviceProvider"
                type="text"
                cols={35}
                placeholder=""
                className="input"
                {...register("serviceProvider")}
              />
            </div>
          </div>
          <div
            className={
              errors.commonName
                ? "job-form-input-container error"
                : "job-form-input-container"
            }
          >
            {errors.commonName && (
              <p className="error-message">
                {" "}
                <span style={{ marginRight: "5px" }}>
                  <FaExclamationCircle />
                </span>
                {errors.commonName.message}
              </p>
            )}
            <div className="job-form-input">
              <label htmlFor="commonName">Common/Branded Name</label>
              <input
                id="commonName"
                type="text"
                cols={35}
                placeholder=""
                className="input"
                {...register("commonName")}
              />
            </div>
          </div>
          <div
            className={
              errors.locationAddress
                ? "job-form-input-container error"
                : "job-form-input-container"
            }
          >
            {errors.locationAddress && (
              <p className="error-message">
                <span style={{ marginRight: "5px" }}>
                  <FaExclamationCircle />
                </span>
                {errors.locationAddress.message}
              </p>
            )}
            <div className="job-form-input">
              <label htmlFor="locationAddress">Location Address</label>
              <input
                id="locationAddress"
                type="text"
                cols={35}
                placeholder=""
                className="input"
                {...register("locationAddress")}
              />
            </div>
          </div>

          <div className="city-state-zip">
            <div
              className={
                errors.city
                  ? "job-form-input-container error"
                  : "job-form-input-container"
              }
            >
              {errors.city && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.city.message}
                </p>
              )}
              <div className="job-form-input">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  cols={35}
                  placeholder=""
                  className="input"
                  {...register("city")}
                />
              </div>
            </div>

            <div
              className={
                errors.state
                  ? "job-form-input-container error"
                  : "job-form-input-container"
              }
            >
              {errors.state && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.state.message}
                </p>
              )}
              <div className="job-form-input">
                <label htmlFor="state">State</label>
                <Controller
                  name="state"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      id="state"
                      className="select-input"
                      placeholder="Select state"
                      options={stateOptions}
                      onChange={(selectedOption) => onChange(selectedOption)}
                      isClearable
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>

            <div
              className={
                errors.zipcode
                  ? "job-form-input-container error"
                  : "job-form-input-container"
              }
            >
              {errors.zipcode && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.zipcode.message}
                </p>
              )}
              <div className="job-form-input">
                <label htmlFor="zipcode">Zip</label>
                <input
                  id="zipcode"
                  type="text"
                  cols={35}
                  placeholder=""
                  className="input"
                  {...register("zipcode")}
                />
              </div>
            </div>

            {/* <Input
                {...stateValidation}
                options={stateOptions}
                control={control}
              /> */}
          </div>
          <div
            className={
              errors.sqftLeased
                ? "job-form-input-container error"
                : "job-form-input-container"
            }
          >
            {errors.sqftLeased && (
              <p className="error-message">
                <span style={{ marginRight: "5px" }}>
                  <FaExclamationCircle />
                </span>
                {errors.sqftLeased.message}
              </p>
            )}
            <div className="job-form-input sqft">
              <label htmlFor="sqftLeased">Square Feet Leased</label>
              <input
                id="sqftLeased"
                type="number"
                placeholder=""
                {...register("sqftLeased")}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
              />
            </div>
          </div>

          <div className="job-form-input-container">
            <div className="job-form-input">
              <label htmlFor="description">Additional Description</label>
              <textarea
                id="description"
                rows={2}
                placeholder=""
                className="input"
                cols={35}
                {...register("description")}
              />
            </div>
          </div>

          <div className="startdate-term">
            <div
              className={
                errors.startDate
                  ? "job-form-input-container error"
                  : "job-form-input-container"
              }
            >
              {errors.startDate && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.startDate.message}
                </p>
              )}
              <div className="job-form-input">
                <label htmlFor="startDate">Expected Start Date</label>
                <input
                  id="startDate"
                  type="date"
                  cols={35}
                  placeholder="mm/dd/yyyy"
                  className="input"
                  {...register("startDate")}
                />
              </div>
            </div>

            <div
              className={
                errors.term
                  ? "job-form-input-container error"
                  : "job-form-input-container"
              }
            >
              {errors.term && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.term.message}
                </p>
              )}
              <div className="job-form-input">
                <label style={{ width: "170px" }} htmlFor="term">
                  Expected Term
                </label>
                <Controller
                  name="term"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      id="term"
                      className="select-input"
                      placeholder="Select Term"
                      options={termOptions}
                      onChange={(selectedOption) => onChange(selectedOption)}
                      value={value}
                      onBlur={onBlur}
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div
            className={
              errors.desks
                ? "job-form-input-container error"
                : "job-form-input-container"
            }
          >
            {errors.desks && (
              <p className="error-message">
                <span style={{ marginRight: "5px" }}>
                  <FaExclamationCircle />
                </span>
                {errors.desks.message}
              </p>
            )}
            <div className="job-form-input">
              <label htmlFor="desks">
                Number of Desks/Workstations in the Leased Space
              </label>
              <input
                id="desks"
                type="number"
                placeholder=""
                {...register("desks")}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
              />
            </div>
          </div>

          <div className="check-header-container">
            <p className="checkbox-header">{`Space Descriptions: `}</p>
            <span className="space-extra">
              {`[provide any photos, floorplans, or other information as
                necessary]`}
            </span>
          </div>
          <div className="checkbox-outer-container">
            <div
              className={
                errors.propertyType
                  ? "property-type-container error"
                  : "property-type-container"
              }
            >
              {errors.propertyType && (
                <p className="error-message">
                  {" "}
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.propertyType.message}
                </p>
              )}
              <p className="checkbox-heading">Property Type</p>
              <Controller
                name="propertyType"
                control={control}
                defaultValue="" // Default value can be empty or a specific value
                render={({ field }) => (
                  <div>
                    <label className="radio-container" htmlFor="prop-office">
                      Office
                      <div className="job-form-input-radio-check">
                        <input
                          id="prop-office"
                          type="radio"
                          value="Office"
                          checked={field.value === "Office"}
                          onChange={() => field.onChange("Office")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                    <label
                      className="radio-container"
                      htmlFor="prop-industrial"
                    >
                      Industrial/Warehouse
                      <div className="job-form-input-radio-check">
                        <input
                          id="prop-industrial"
                          type="radio"
                          value="Industrial/Warehouse"
                          checked={field.value === "Industrial/Warehouse"}
                          onChange={() =>
                            field.onChange("Industrial/Warehouse")
                          }
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                    <label className="radio-container" htmlFor="prop-retail">
                      Retail
                      <div className="job-form-input-radio-check">
                        <input
                          id="prop-retail"
                          type="radio"
                          value="Retail"
                          checked={field.value === "Retail"}
                          onChange={() => field.onChange("Retail")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                    <label className="radio-container" htmlFor="prop-mix">
                      Mixed/Other
                      <div className="job-form-input-radio-check">
                        <input
                          id="prop-mix"
                          type="radio"
                          value="Mixed/Other"
                          checked={field.value === "Mixed/Other"}
                          onChange={() => field.onChange("Mixed/Other")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                )}
              />
            </div>
            <div
              className={
                errors.spaceType
                  ? "space-type-container error"
                  : "space-type-container"
              }
            >
              {errors.spaceType && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.spaceType.message}
                </p>
              )}
              <p className="checkbox-heading">Space Type</p>
              <Controller
                name="spaceType"
                control={control}
                defaultValue="" // Default value can be empty or a specific value
                render={({ field }) => (
                  <div>
                    <label className="radio-container" htmlFor="space-private">
                      Private office(s)
                      <div className="job-form-input-radio-check">
                        <input
                          id="space-private"
                          type="radio"
                          value="Private office"
                          checked={field.value === "Private office"}
                          onChange={() => field.onChange("Private office")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                    <label className="radio-container" htmlFor="space-cube">
                      Cubicle(s)/Other
                      <div className="job-form-input-radio-check">
                        <input
                          id="space-cube"
                          type="radio"
                          value="Cubicle"
                          checked={field.value === "Cubicle"}
                          onChange={() => field.onChange("Cubicle")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                )}
              />
            </div>
            <div
              className={
                errors.settingType
                  ? "setting-type-container error"
                  : "setting-type-container"
              }
            >
              {errors.settingType && (
                <p className="error-message">
                  <span style={{ marginRight: "5px" }}>
                    <FaExclamationCircle />
                  </span>
                  {errors.settingType.message}
                </p>
              )}
              <p className="checkbox-heading">Setting</p>
              <Controller
                name="settingType"
                control={control}
                defaultValue="" // Default value can be empty or a specific value
                render={({ field }) => (
                  <div>
                    <label
                      className="radio-container"
                      htmlFor="setting-interior"
                    >
                      Interior
                      <div className="job-form-input-radio-check">
                        <input
                          id="setting-interior"
                          type="radio"
                          value="Interior"
                          checked={field.value === "Interior"}
                          onChange={() => field.onChange("Interior")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                    <label className="radio-container" htmlFor="setting-window">
                      Exterior/Window
                      <div className="job-form-input-radio-check">
                        <input
                          id="setting-window"
                          type="radio"
                          value="Window"
                          checked={field.value === "Window"}
                          onChange={() => field.onChange("Window")}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="check-header-container">
            <p className="checkbox-header">{`Services Provided in Lease: `}</p>
          </div>
          <div className="checkbox-outer-container">
            <div className="property-type-container">
              <p className="checkbox-heading">Facilities</p>
              <Controller
                name="facility"
                control={control}
                defaultValue={[]} // Default value as an empty array
                render={({ field }) => (
                  <div>
                    <label className="radio-container" htmlFor="fac-cam">
                      Common area maintenance
                      <div className="job-form-input-radio-check">
                        <input
                          id="fac-cam"
                          type="checkbox"
                          value="cam"
                          checked={field.value.includes("cam")}
                          onChange={() => {
                            console.log(field);
                            const newValue = field.value.includes("cam")
                              ? field.value.filter((v) => v !== "cam")
                              : [...field.value, "cam"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["cam"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                cam: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="fac-furnished">
                      Furniture and furnishings
                      <div className="job-form-input-radio-check">
                        <input
                          id="fac-furnished"
                          type="checkbox"
                          value="furnished"
                          checked={field.value.includes("furnished")}
                          onChange={() => {
                            const newValue = field.value.includes("furnished")
                              ? field.value.filter((v) => v !== "furnished")
                              : [...field.value, "furnished"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["furnished"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                furnished: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="fac-janitorial">
                      Janitorial
                      <div className="job-form-input-radio-check">
                        <input
                          id="fac-janitorial"
                          type="checkbox"
                          value="janitorial"
                          checked={field.value.includes("janitorial")}
                          onChange={() => {
                            const newValue = field.value.includes("janitorial")
                              ? field.value.filter((v) => v !== "janitorial")
                              : [...field.value, "janitorial"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="fac-parking">
                      Parking
                      <div className="job-form-input-radio-check">
                        <input
                          id="fac-parking"
                          type="checkbox"
                          value="parking"
                          checked={field.value.includes("parking")}
                          onChange={() => {
                            const newValue = field.value.includes("parking")
                              ? field.value.filter((v) => v !== "parking")
                              : [...field.value, "parking"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="fac-tax">
                      Property insurance and taxes
                      <div className="job-form-input-radio-check">
                        <input
                          id="fac-tax"
                          type="checkbox"
                          value="tax"
                          checked={field.value.includes("tax")}
                          onChange={() => {
                            const newValue = field.value.includes("tax")
                              ? field.value.filter((v) => v !== "tax")
                              : [...field.value, "tax"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["tax"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                tax: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                )}
              />
            </div>
            <div className="space-type-container">
              <p className="checkbox-heading">Utilities</p>
              <Controller
                name="utility"
                control={control}
                defaultValue={[]} // Default value as an empty array
                render={({ field }) => (
                  <div>
                    <label
                      className="radio-container"
                      htmlFor="util-electricity"
                    >
                      Electricity
                      <div className="job-form-input-radio-check">
                        <input
                          id="util-electricity"
                          type="checkbox"
                          value="electricity"
                          checked={field.value.includes("electricity")}
                          onChange={() => {
                            const newValue = field.value.includes("electricity")
                              ? field.value.filter((v) => v !== "electricity")
                              : [...field.value, "electricity"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="util-gas">
                      Gas/Oil/Propane
                      <div className="job-form-input-radio-check">
                        <input
                          id="util-gas"
                          type="checkbox"
                          value="gas"
                          checked={field.value.includes("gas")}
                          onChange={() => {
                            const newValue = field.value.includes("gas")
                              ? field.value.filter((v) => v !== "gas")
                              : [...field.value, "gas"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="util-water">
                      Water
                      <div className="job-form-input-radio-check">
                        <input
                          id="util-water"
                          type="checkbox"
                          value="water"
                          checked={field.value.includes("water")}
                          onChange={() => {
                            const newValue = field.value.includes("water")
                              ? field.value.filter((v) => v !== "water")
                              : [...field.value, "water"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="util-phone">
                      Phone
                      <div className="job-form-input-radio-check">
                        <input
                          id="util-phone"
                          type="checkbox"
                          value="phone"
                          checked={field.value.includes("phone")}
                          onChange={() => {
                            const newValue = field.value.includes("phone")
                              ? field.value.filter((v) => v !== "phone")
                              : [...field.value, "phone"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="util-internet">
                      Internet/WiFi
                      <div className="job-form-input-radio-check">
                        <input
                          id="util-internet"
                          type="checkbox"
                          value="internet"
                          checked={field.value.includes("internet")}
                          onChange={() => {
                            const newValue = field.value.includes("internet")
                              ? field.value.filter((v) => v !== "internet")
                              : [...field.value, "internet"];
                            field.onChange(newValue);
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                )}
              />
            </div>
            <div className="setting-container">
              <p className="checkbox-heading">Amenities</p>
              <Controller
                name="amenity"
                control={control}
                defaultValue={[]} // Default value as an empty array
                render={({ field }) => (
                  <div>
                    <label className="radio-container" htmlFor="amen-meeting">
                      Furnished conference room use
                      <div className="job-form-input-radio-check">
                        <input
                          id="amen-meeting"
                          type="checkbox"
                          value="meeting"
                          checked={field.value.includes("meeting")}
                          onChange={() => {
                            const newValue = field.value.includes("meeting")
                              ? field.value.filter((v) => v !== "meeting")
                              : [...field.value, "meeting"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["meeting"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                meeting: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="amen-kitchen">
                      Kitchen
                      <div className="job-form-input-radio-check">
                        <input
                          id="amen-kitchen"
                          type="checkbox"
                          value="kitchen"
                          checked={field.value.includes("kitchen")}
                          onChange={() => {
                            const newValue = field.value.includes("kitchen")
                              ? field.value.filter((v) => v !== "kitchen")
                              : [...field.value, "kitchen"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["kitchen"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                kitchen: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label className="radio-container" htmlFor="amen-copy">
                      Copy/fax/printer/scanner use
                      <div className="job-form-input-radio-check">
                        <input
                          id="amen-copy"
                          type="checkbox"
                          value="copy"
                          checked={field.value.includes("copy")}
                          onChange={() => {
                            const newValue = field.value.includes("copy")
                              ? field.value.filter((v) => v !== "copy")
                              : [...field.value, "copy"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["copy"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                copy: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>

                    <label
                      className="radio-container"
                      htmlFor="amen-receptionist"
                    >
                      Receptionist/Answering
                      <div className="job-form-input-radio-check">
                        <input
                          id="amen-receptionist"
                          type="checkbox"
                          value="receptionist"
                          checked={field.value.includes("receptionist")}
                          onChange={() => {
                            const newValue = field.value.includes(
                              "receptionist"
                            )
                              ? field.value.filter((v) => v !== "receptionist")
                              : [...field.value, "receptionist"];
                            field.onChange(newValue);
                          }}
                          onFocus={() => {
                            if (overrideState["receptionist"]) {
                              setOverrideState((prev) => ({
                                ...prev,
                                receptionist: false,
                              }));
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="checkbox-outer-container">
            <div className="property-type-container">
              <p className="checkbox-header upload-header">{`Upload images: `}</p>
            </div>
          </div>
          {existingExhibit.fileUrls?.length > 0 && (
            <div className="image-edit-wrap">
              {existingExhibit.fileUrls.map((url) => (
                <ImageWithCloseButton
                  src={url}
                  newFileUrls={newFileUrls}
                  setNewFileUrls={setNewFileUrls}
                  className="edit-image"
                  alt="Your Image"
                />
              ))}
            </div>
          )}
          {existingExhibit.fileUrls?.length > 0 ? (
            <h3>Add more files</h3>
          ) : (
            <h3>Add files</h3>
          )}
          <input type="file" onChange={handleChange} multiple />
          <div className="button-container">
            <button
              onClick={() => navigate("/dashboard")}
              className="submit-btn exhibit-save"
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className="submit-btn exhibit-submit"
              type="submit"
            >
              Save & Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
