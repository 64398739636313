import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar">
      <img
        className="logo"
        alt="MLinc Solutions Logo"
        src="https://mlincsolutions.com/wp-content/uploads/2021/12/Logo.png"
      />
      <div className="nav-container">
        <ul className="nav-ul">
          <Link className="nav-item" to="">
            <li>Home</li>
          </Link>
          <Link className="nav-item" to="">
            <li>Solutions</li>
          </Link>
          <Link className="nav-item" to="">
            <li>FAQ</li>
          </Link>
          <Link className="nav-item" to="">
            <li>About Us</li>
          </Link>
        </ul>
        <button className="nav-btn">Contact Us</button>
        <Link className="login-link" to="/">
          Log In
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
