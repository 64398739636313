import React, { useState } from "react";
import "./image.css";
import ConfirmationModal from "./ConfirmationModal"; // Assuming this is your modal component

const ImageWithCloseButton = ({ src, alt, newFileUrls, setNewFileUrls }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmClose = () => {
    const updatedUrls = newFileUrls.filter((url) => {
      const stringUrl = String(url).trim();
      const stringSrc = String(src).trim();

      console.log(`Comparing '${stringUrl}' to '${stringSrc}'`);

      return stringUrl !== stringSrc;
    });

    console.log("Filtered URLs:", updatedUrls);

    setNewFileUrls(updatedUrls);
    setIsVisible(false);
    closeModal();
  };

  if (!isVisible) return null;

  return (
    <div className="image-container">
      <img src={src} alt={alt} />
      <button className="close-button" onClick={openModal}>
        ×
      </button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmClose}
      />
    </div>
  );
};

export default ImageWithCloseButton;
