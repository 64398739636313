export const amenityOptions = [
  { value: "meeting", label: "Furnished conference room use" },
  { value: "kitchen", label: "Kitchen" },
  { value: "copy", label: "Copy/fax/printer/scanner use" },
  { value: "receptionist", label: "Receptionist/Answering" },
];

export const facilityOptions = [
  { value: "cam", label: "Common area maintenance" },
  { value: "furnished", label: "Furniture and furnishings" },
  { value: "janitorial", label: "Janitorial" },
  { value: "parking", label: "Parking" },
  { value: "tax", label: "Property insurance and taxes" },
];

export const propertyOptions = [
  { value: "Office", label: "Office" },
  { value: "Industrial/Warehouse", label: "Industrial/Warehouse" },
  { value: "Retail", label: "Retail" },
  // { value: "Flex", label: "Flex" },
  // { value: "Medical", label: "Medical" },
  { value: "Mixed/Other", label: "Mixed/Other" },
];

export const settingOptions = [
  { value: "Interior", label: "Interior" },
  { value: "Window", label: "Exterior/Window" },
];

export const spaceOptions = [
  { value: "Private office", label: "Private office(s)" },
  { value: "cubicle", label: "Cubicle(s)/Other" },
];

export const termOptions = [
  { value: "Month-to-month", label: "Month-to-month" },
  { value: "1-3", label: "1-3 months" },
  { value: "4-6", label: "4-6 months" },
  { value: "7-9", label: "7-9 months" },
  { value: "10-12", label: "10-12 months" },
  { value: "12-18", label: "12-18 months" },
  { value: ">18", label: "More than 18 months" },
  { value: "N/A", label: "N/A" },
];

export const utilityOptions = [
  { value: "electricity", label: "Electricity" },
  { value: "gas", label: "Gas/Oil/Propane" },
  { value: "water", label: "Water" },
  { value: "phone", label: "Phone" },
  { value: "internet", label: "Internet/WiFi" },
];

export const stateOptions = [
  { value: "AL", label: "AL - Alabama" },
  { value: "AK", label: "AK - Alaska" },
  { value: "AZ", label: "AZ - Arizona" },
  { value: "AR", label: "AR - Arkansas" },
  { value: "CA", label: "CA - California" },
  { value: "CO", label: "CO - Colorado" },
  { value: "CT", label: "CT - Connecticut" },
  { value: "DE", label: "DE - Delaware" },
  { value: "DC", label: "DC - District Of Columbia" },
  { value: "FL", label: "FL - Florida" },
  { value: "GA", label: "GA - Georgia" },
  { value: "HI", label: "HI - Hawaii" },
  { value: "ID", label: "ID - Idaho" },
  { value: "IL", label: "IL - Illinois" },
  { value: "IN", label: "IN - Indiana" },
  { value: "IA", label: "IA - Iowa" },
  { value: "KS", label: "KS - Kansas" },
  { value: "KY", label: "KY - Kentucky" },
  { value: "LA", label: "LA - Louisiana" },
  { value: "ME", label: "ME - Maine" },
  { value: "MD", label: "MD - Maryland" },
  { value: "MA", label: "MA - Massachusetts" },
  { value: "MI", label: "MI - Michigan" },
  { value: "MN", label: "MN - Minnesota" },
  { value: "MS", label: "MS - Mississippi" },
  { value: "MO", label: "MO - Missouri" },
  { value: "MT", label: "MT - Montana" },
  { value: "NE", label: "NE - Nebraska" },
  { value: "NV", label: "NV - Nevada" },
  { value: "NH", label: "NH - New Hampshire" },
  { value: "NJ", label: "NJ - New Jersey" },
  { value: "NM", label: "NM - New Mexico" },
  { value: "NY", label: "NY - New York" },
  { value: "NC", label: "NC - North Carolina" },
  { value: "ND", label: "ND - North Dakota" },
  { value: "OH", label: "OH - Ohio" },
  { value: "OK", label: "OK - Oklahoma" },
  { value: "OR", label: "OR - Oregon" },
  { value: "PA", label: "PA - Pennsylvania" },
  { value: "PR", label: "PR - Puerto Rico" },
  { value: "RI", label: "RI - Rhode Island" },
  { value: "SC", label: "SC - South Carolina" },
  { value: "SD", label: "SD - South Dakota" },
  { value: "TN", label: "TN - Tennessee" },
  { value: "TX", label: "TX - Texas" },
  { value: "UT", label: "UT - Utah" },
  { value: "VT", label: "VT - Vermont" },
  { value: "VI", label: "VI - Virgin Islands" },
  { value: "VA", label: "VA - Virginia" },
  { value: "WA", label: "WA - Washington" },
  { value: "WV", label: "WV - West Virginia" },
  { value: "WI", label: "WI - Wisconsin" },
  { value: "WY", label: "WY - Wyoming" },
];
