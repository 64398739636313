import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase.config";
import { Input } from "../Components/Input";
import { agreeToTermsValidation } from "../utils/inputValidations";
import { useForm, FormProvider } from "react-hook-form";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { slugify } from "../utils/helperFunctions";

const Review = ({ pdfExhibit, imgExhibit, reviewExhibitID }) => {
  const [pdfURL, setPdfURL] = useState(null);
  const [imgURLs, setImgURLs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exhibitData, setExhibitData] = useState(null);

  const methods = useForm();
  const { control } = methods;
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    if (!auth.currentUser) navigate("/");
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "exhibits", reviewExhibitID),
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Exhibit data retrieved:", data);
          setExhibitData(data);
          setPdfURL(data.pdfURL);
          setImgURLs(data.fileUrls || []); // Handle undefined fileUrls
        } else {
          console.log("No such document!");
        }
        setLoading(false); // Set loading to false regardless of success or failure
      },
      (error) => {
        console.error("Error fetching exhibit data:", error);
        setLoading(false); // Handle loading state in case of error
      }
    );

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [reviewExhibitID]);

  const editExhibit = async (exhibit) => {
    console.log(exhibit);
    try {
      await updateDoc(doc(db, "exhibits", exhibit.id), {
        status: "Submitted",
      });
      alert(
        "Your exhibit has been submitted to MLinc Solutions for valuation."
      );
      navigate("/dashboard");
    } catch (e) {
      console.error("Error adding document", e);
    }
  };

  useEffect(() => {
    console.log("ExhibitData:", exhibitData);
  }, [exhibitData]);

  const handleSubmit = methods.handleSubmit(async (data, e) => {
    e.preventDefault();
    editExhibit(exhibitData);
  });

  if (loading) return <div>Loading...</div>;

  return (
    <div className="review-container">
      <h2 className="review-h2">Review Your Submission</h2>
      <div className="inner-rev-container">
        <div className="pdf-viewer-container">
          {pdfURL && (
            <iframe
              src={pdfURL}
              width={1000}
              height={950}
              title="PDF Viewer"
            ></iframe>
          )}

          {window.location.href.endsWith("review") ? (
            <FormProvider {...methods}>
              <form
                className="submit-review-form"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <div className="agree-check">
                  <Input control={control} {...agreeToTermsValidation} />
                  <p className="terms-p">
                    By checking this box, you are electronically signing your
                    order and authorizing us to charge payments against the
                    credit card on file with MLinc Mortgage Solutions.
                  </p>
                </div>

                <div className="button-container">
                  <button
                    className="retrn-btn submit-btn exhibit-save"
                    onClick={() => {
                      navigate(`/dashboard`);
                    }}
                  >
                    Save & Exit
                  </button>
                  <button className="submit-btn exhibit-submit" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </FormProvider>
          ) : (
            <button
              className="retrn-btn submit-btn exhibit-submit"
              onClick={() => navigate("/dashboard")}
            >
              Return to Dashboard
            </button>
          )}
        </div>

        {imgURLs.length > 0 && (
          <div className="image-review-container">
            <h3>Images</h3>
            <div className="image-review">
              {imgURLs.map((imgURL, index) => (
                <img
                  key={index}
                  src={imgURL}
                  alt={`Exhibit Image ${index + 1}`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
