import React from "react";
import "./confirmation.css";
import { FaTrashAlt } from "react-icons/fa";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Are you sure?</h2>
        <p className="modal-p">
          Once you remove this image it will be lost forever. Do you still wish
          to continue?
        </p>
        <div className="modal-buttons">
          <button className="cancel-modal" onClick={onClose}>
            No
          </button>
          <button className="delete-modal" onClick={onConfirm}>
            <FaTrashAlt /> Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
