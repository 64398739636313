import React from "react";
import { db } from "../firebase.config";
import { deleteDoc, doc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { deleteExhibit } from "../Redux/Exhibits/exhibitSlice";

function Modal({ setModalToggle, deleteExhbitID }) {
  const dispatch = useDispatch();

  const deleteExhibitFirestore = async (exhibit) => {
    try {
      const docRef = doc(db, "exhibits", exhibit);
      await deleteDoc(docRef);
      setModalToggle(false);
    } catch (e) {
      console.error("Error deleting document", e);
    }
  };

  return (
    <div className="modal-container">
      <div className="my-modal">
        <h2>Are you sure you want to delete this exhibit?</h2>
        <h2 className="lost-forever">It will be lost forever</h2>
        <div className="modal-btn-cont">
          <button
            className="modal-delete"
            onClick={() => {
              dispatch(deleteExhibit(deleteExhbitID));
              deleteExhibitFirestore(deleteExhbitID);
            }}
          >
            Yes
          </button>
          <button
            className="modal-cancel"
            onClick={() => setModalToggle(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
