import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const exhibitSlice = createSlice({
  name: "exhibit",
  initialState: initialState,
  reducers: {
    addExhibits: (state, { payload }) => {
      return (state = payload);
    },
    editExhibits: (state, { payload }) => {
      return { ...state, ...payload };
    },
    deleteExhibit: (state, { payload }) => {
      return state.filter((exhibit) => exhibit.id !== payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addExhibits, editExhibits, deleteExhibit } =
  exhibitSlice.actions;

export default exhibitSlice.reducer;
