import React, { useState } from "react";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";
import Form from "../Components/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, addExhibit, db } from "../firebase.config";
import { storage } from "../firebase.config";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 } from "uuid";
import { FormProvider, useForm } from "react-hook-form";
import EditForm from "../Components/EditForm";
import { doc, updateDoc } from "firebase/firestore";
import { slugify } from "../utils/helperFunctions";
import { useSelector } from "react-redux";

const Edit = ({ exhibit, currentDate, user, setPdfExhibit, setImgExhibit }) => {
  const [exhibitData, setExhibitData] = useState({});
  const [fileUploads, setFileUploads] = useState([]);
  const [pdfURL, setPdfURL] = useState(null);
  const navigate = useNavigate();
  const propertyTypeSelector = (propertyType) => {};
  const newDate = new Date();
  const timeStamp = newDate.toJSON().replaceAll(":", "-").replaceAll(".", "-");
  const location = useLocation();
  const [imageUpload, setImageUPload] = useState(null);
  const imagePath = `images/${imageUpload?.name + v4()}`;
  const uploadImage = () => {};
  const sluggedCompany = slugify(user.companyName);

  const userData = useSelector((state) => state.user);
  const approvedRep = `${userData.contactFirstname} + ${userData.contactLastName}`;

  // async function getPDF() {
  //   if (pdfExhibit) {
  //     const pdfURL = await getDownloadURL(ref(storage, pdfExhibit));
  //     setPdfURL(pdfURL);
  //     console.log(pdfURL);
  //   }
  // }

  console.log("EXHIBIT:", exhibit);

  const editExhibit = async (exhibit, toContinue) => {
    console.log(exhibit);
    try {
      await updateDoc(doc(db, "exhibits", exhibit.id), {
        ...exhibit,
      });
      console.log("Document updated");
      await updateDoc(doc(db, "exhibits", exhibit.id), {
        fileUrls: exhibit.fileUrls,
      });
      if (exhibit.status === "Saved" && toContinue !== "continue")
        navigate("/dashboard");
      else navigate("/review");
    } catch (e) {
      console.error("Error adding document", e);
    }
  };

  console.log(user);

  async function fillForm(exhibitDataForm, toContinue) {
    console.log(exhibitDataForm);
    const formUrl =
      "https://www.mlinc.collinicoding.com/ComplyOfficeExhibit.pdf";
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    const serviceProviderLessor = form.getTextField("Service Provider Lessor");
    const commonName = form.getTextField("Common/Branded Name");
    const address = form.getTextField("Location Address");
    const city = form.getTextField("City");
    const state = form.getTextField("State");
    const zipCode = form.getTextField("Zip Code");
    const description = form.getTextField("Additional Description");
    const startDate = form.getTextField("Expected Start Date");
    const sqftLeased = form.getTextField("Square Feet Leased");
    const expectedTerm = form.getTextField("Expected Term of Lease");
    const numberOfDesks = form.getTextField("Number of Desks/Workstations");
    const companyName = form.getTextField("Company Lessee");
    const signature = form.getField("Signature");
    const signDate = form.getField("Date Signed");

    const office = form.getCheckBox("Office");
    const industrial = form.getCheckBox("Industrial/Warehouse");
    const retail = form.getCheckBox("Retail");
    const mixed = form.getCheckBox("Mixed/Other");
    const privateOffice = form.getCheckBox("Private Office");
    const cubicle = form.getCheckBox("Cubicle");
    const interior = form.getCheckBox("Interior");
    const exterior = form.getCheckBox("Exterior/Window");
    const cam = form.getCheckBox("Common area maintenance");
    const furnishings = form.getCheckBox("Furniture and furnishings");
    const janitorial = form.getCheckBox("Janitorial");
    const parking = form.getCheckBox("Parking");
    const insurace = form.getCheckBox("Property insurance and taxes");
    const electirc = form.getCheckBox("Electricity");
    const gas = form.getCheckBox("Gas/Oil/Propane");
    const water = form.getCheckBox("Water");
    const phone = form.getCheckBox("Phone");
    const internet = form.getCheckBox("Internet/WiFi");
    const conference = form.getCheckBox("Furnished conference room(s) use");
    const kitchen = form.getCheckBox("Kitchen");
    const copyFax = form.getCheckBox("Copy/fax/printer/scanner use");
    const receptionist = form.getCheckBox("Receptionist/Answering");

    // I need to add the ability to add attachments

    let newExhibit = {
      serviceProvider: exhibitDataForm.serviceProvider,
      commonName: exhibitDataForm.commonName,
      locationAddress: exhibitDataForm.locationAddress,
      city: exhibitDataForm.city,
      state: exhibitDataForm.state,
      zipcode: exhibitDataForm.zipcode,
      sqftLeased: exhibitDataForm.sqftLeased,
      term: exhibitDataForm.term,
      desks: exhibitDataForm.desks,
      propertyType: exhibitDataForm.propertyType,
      spaceType: exhibitDataForm.spaceType,
      settingType: exhibitDataForm.settingType,
      facility: exhibitDataForm.facility,
      utility: exhibitDataForm.utility,
      amenity: exhibitDataForm.amenity,
      description: exhibitDataForm.description,
      startDate: exhibitDataForm.startDate,
      companyName: user.companyName,
      signature: approvedRep,
      signDate: currentDate,
      status: "Saved",
      id: exhibit.id,
    };
    //this is where the form information will be taken and used to fill in the pdf form

    serviceProviderLessor.setText(exhibitDataForm.serviceProvider);
    commonName.setText(exhibitDataForm.commonName);
    address.setText(exhibitDataForm.locationAddress);
    city.setText(exhibitDataForm.city);
    state.setText(exhibitDataForm.state);
    zipCode.setText(exhibitDataForm.zipcode);
    sqftLeased.setText(exhibitDataForm.sqftLeased);
    description.setText(exhibitDataForm.description);
    startDate.setText(exhibitDataForm.startDate);
    expectedTerm.setText(exhibitDataForm.term);
    numberOfDesks.setText(exhibitDataForm.desks);
    companyName.setText(user.companyName);
    signature.setText(user.approvedRep);
    signDate.setText(currentDate);

    switch (exhibitDataForm.propertyType) {
      case "Office":
        office.check();
        break;
      case "Industrial/Warehouse":
        industrial.check();
        break;
      case "Retail":
        retail.check();
        break;
      case "Mixed/Other":
        mixed.check();
        break;
      default:
        break;
    }

    switch (exhibitDataForm.spaceType) {
      case "Private office":
        privateOffice.check();
        break;
      case "cubicle":
        cubicle.check();
        break;
      default:
        break;
    }

    switch (exhibitDataForm.settingType) {
      case "Interior":
        interior.check();
        break;
      case "Window":
        exterior.check();
        break;
      default:
        break;
    }

    exhibitDataForm.facility?.forEach((item) => {
      switch (item) {
        case "cam":
          cam.check();
          break;
        case "furnished":
          furnishings.check();
          break;
        case "janitorial":
          janitorial.check();
          break;
        case "parking":
          parking.check();
          break;
        case "tax":
          insurace.check();
          break;
        default:
          break;
      }
    });

    exhibitDataForm.utility?.forEach((item) => {
      switch (item) {
        case "electricity":
          electirc.check();
          break;
        case "gas":
          gas.check();
          break;
        case "water":
          water.check();
          break;
        case "phone":
          phone.check();
          break;
        case "internet":
          internet.check();
          break;
        default:
          break;
      }
    });

    exhibitDataForm.amenity?.forEach((item) => {
      switch (item) {
        case "meeting":
          conference.check();
          break;
        case "kitchen":
          kitchen.check();
          break;
        case "copy":
          copyFax.check();
          break;
        case "receptionist":
          receptionist.check();
          break;
        default:
          break;
      }
    });

    const pdfBytes = await pdfDoc.save();
    const newFile = new File([pdfBytes], "myTest.pdf", {
      type: "application/pdf",
    });

    // Create a reference for the PDF file in Firebase Storage
    const pdfRef = ref(
      storage,
      `${sluggedCompany}/pdfs/complyOfficeExhibit${timeStamp}`
    );
    // Upload the PDF
    const pdfUploadTask = uploadBytesResumable(pdfRef, newFile);

    // Handle PDF upload progress and completion
    pdfUploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(`PDF upload is ${progress}% done`);
      },
      (error) => {
        console.error("PDF upload error:", error);
      },
      async () => {
        console.log("PDF upload complete, fetching URL...");
        const pdfURL = await getDownloadURL(pdfUploadTask.snapshot.ref);
        console.log(`PDF is now available at ${pdfURL}`);

        newExhibit.pdfURL = pdfURL; // Store the URL in your exhibit object

        // Prepare for other file uploads
        const promises = [];
        const uploadedUrls = []; // Array to hold the uploaded URLs

        // Add the filtered URLs to the uploadedUrls array
        if (exhibitDataForm?.fileurls)
          uploadedUrls.push(...exhibitDataForm.fileUrls);

        fileUploads.forEach((file, index) => {
          const fileName = file.name || file.label || `file_${index}`;
          const storageRef = ref(
            storage,
            `/${sluggedCompany}/uploads/${fileName}`
          );

          // Create a resumable upload task for each file
          const uploadTask = uploadBytesResumable(storageRef, file);

          // Add progress listener
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              console.log(`Upload #${index + 1} is ${progress}% done`);
            },
            (error) => {
              console.error(`Upload #${index + 1} error:`, error);
            },
            async () => {
              console.log(`Upload #${index + 1} is complete, fetching URL...`);
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              console.log(`Upload #${index + 1} is now available at ${url}.`);
              uploadedUrls.push(url); // Add the URL to the array
            }
          );

          // Wait for the upload task to complete
          promises.push(uploadTask);
        });

        // Wait for all uploads to complete
        Promise.all(promises)
          .then(() => {
            // Now that all uploads are done, ensure that fileUrls is updated correctly
            newExhibit.fileUrls = uploadedUrls;

            // Now add the exhibit with the updated URLs
            editExhibit(
              { ...newExhibit, fileUrls: newExhibit.fileUrls },
              toContinue
            );
          })
          .catch((err) => console.error("Error during file uploads:", err));
      }
    );
  }

  return (
    <div>
      <EditForm
        uploadImage={uploadImage}
        imageUpload={imageUpload}
        setImageUPload={setImageUPload}
        setExhibitData={setExhibitData}
        currentDate={currentDate}
        fillForm={fillForm}
        existingExhibit={exhibit}
        setFileUploads={setFileUploads}
      />
    </div>
  );
};

export default Edit;
