import React, { useState, useId, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../firebase.config";
import { useDispatch } from "react-redux";
import { editUser } from "../Redux/User/userSlice";
import { setLoader } from "../Redux/Loader/loaderSlice";

const Login = ({ setUser }) => {
  const navigate = useNavigate();
  const id = useId();
  const form = useForm();
  const { register, control, handleSubmit, formState } = form;
  const { errors } = formState;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    console.log("FORM SUBMITTED", data);
    let email = data.username;
    let pass = data.password;
    logInWithEmailAndPassword(auth, email, pass);
  };

  const logInWithEmailAndPassword = async (auth, email, password) => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = res.user;
      try {
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();
        data.loggedIn = true;
        dispatch(editUser(data));
        if (data.loggedIn) navigate("/dashboard");
        // data.agency === "applicant"
        //   ? navigate("/applicant-home")
        //   : data.agency !== "applicant" &&
        //     data.agency !== "default" &&
        //     data.agency !== "MINRC Job Portal Admin"
        //   ? navigate("/agency-home")
        //   : data.agency === "MINRC Job Portal Admin"
        //   ? navigate("/super-home")
        //   : navigate("/");
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
      return user;
    } catch (err) {
      dispatch(setLoader(false));
      setErrorMessage("Username or password is invalid.");
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-group">
          <div className="login-element">
            <label className="nowrap" htmlFor={`username-${id}`}>
              Username:
            </label>
            <input
              type="email"
              id={`username-${id}`}
              placeholder="Username/email"
              {...register("username", {
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Email format is invalid",
                },
              })}
            ></input>
            <p className="input-error">{errors.username?.message}</p>
          </div>
          <div className="login-element">
            <label className="nowrap" htmlFor={`password-${id}`}>
              Password:
            </label>
            <input
              type="password"
              id={`password-${id}`}
              placeholder="Password"
              {...register("password", {
                required: {
                  value: true,
                  message: "Password required",
                },
              })}
            ></input>
            <p className="input-error">{errors.password?.message}</p>
          </div>
        </div>
        <button
          className="login-btn"
          type="submit"
          // onClick={() => {

          //   navigate("/dashboard")
          // }}
          onSubmit={() => onSubmit()}
        >
          Login
        </button>
      </form>
      <p className="forgot-pass-text">
        <Link to="/forgot-password">Forgot Password</Link>
      </p>
      <DevTool control={control} />
    </div>
  );
};

export default Login;
