import React, { useState } from "react";

const ToolTip = ({ text, children, direction }) => {
  const [isViisible, setIsVisible] = useState(false);

  return (
    <div
      className={"my-tooltip-container"}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isViisible && (
        <div className={direction ? `${direction} my-tooltip` : "my-tooltip"}>
          {text}
        </div>
      )}
    </div>
  );
};

export default ToolTip;
