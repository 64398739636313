import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  serviceProvider: Yup.string()
    .required("Service Provider is required")
    .max(80, "80 characters max")
    .min(5, "Too few characters"),
  commonName: Yup.string().max(80, "80 characters max"),
  locationAddress: Yup.string()
    .required("Location Address is required")
    .max(80, "80 characters max")
    .min(10, "Please enter a valid address"),
  city: Yup.string()
    .required("City is required")
    .max(30, "30 characters max")
    .min(2, "Enter a valid city"),
  state: Yup.object().required("State is required"),
  zipcode: Yup.string()
    .trim() // Remove whitespace from beginning and end of string
    .required() // Set this field to 'required'
    .min(5, "Please enter a valid zipcode") // Set minimum string length
    .max(5, "Please enter a valid zipcode") // Set maximum string length
    .matches(/^[0-9]+$/, "Must be only digits"),
  sqftLeased: Yup.number()
    .required("Square Feet Leased is required")
    .typeError("Square Feet Leased is require")
    .positive("Square Feet Leased must be postiive value")
    .min(1, "Please select a value between 1 and 5000")
    .max(5000, "Please select a value between 1 and 5000"),
  term: Yup.object().required("Expected Term is required"),
  desks: Yup.number()
    .typeError("Number of Desks is required")
    .positive("Desks must be postiive integer")
    .integer("Desks must be a whole integer")
    .required("Number of Desks is required")
    .min(1, "Please select a value between 1 and 50")
    .max(50, "Please select a value between 1 and 50"),
  propertyType: Yup.string()
    .required("Property Type is required")
    .min(6, "Select at least one Property Type"),
  spaceType: Yup.string().required("Space Type is required"),
  settingType: Yup.string()
    .required("Setting is required")
    .min(6, "Select at least one Property Type"),
  facility: Yup.array().required("Facilities are required"),
  utility: Yup.array().required("Utilities are required"),
  amenity: Yup.array().required("Amenities are required"),
  description: Yup.string().max(3000, "3000 characters max"),
  startDate: Yup.string().required("Expected Start Date is required"),
  termsCheck: Yup.boolean().oneOf([true], "You must accept the terms"),
  // Add additional fields as needed
});
