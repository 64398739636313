import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Table } from "react-bootstrap";
import { storage, db } from "../firebase.config";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { error } from "pdf-lib";
import DashboardTable from "../Components/DashboardTable";
import { Input } from "../Components/Input";

const PartnerDash = ({ user }) => {
  const navigate = useNavigate();
  const [exhibits, setExhibits] = useState();
  const [pdfList, setPDFList] = useState([]);
  const pdfListRef = ref(storage, "pdfs/");

  // useEffect(() => {
  //   listAll(pdfListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         setPDFList((prev) => [...prev, url]);
  //       });
  //     });
  //   });
  // }, []);

  async function getExhibits() {
    const exhibitsCollection = collection(db, "exhibits");
    const theExhibits = await getDocs(exhibitsCollection)
      .then((response) => {
        const exhibitsArray = response.docs.map((doc) => ({
          ...doc.data(),
        }));

        console.log(exhibitsArray);
        setExhibits(exhibitsArray);
      })
      .catch((error) => console.log(error.message));
  }

  useEffect(() => {
    getExhibits();
  }, []);

  return (
    <div className="dashboard" style={{ width: "80%", height: "80vh" }}>
      <div className="title-btn-wrap">
        <h2>Welcome Best Home Realty</h2>
        {/* <button className="dashboard-btn add-partner-btn">
          Add New Partner +
        </button> */}
      </div>
      <button
        className="partner-dash-btn"
        type="button"
        onClick={() => navigate("/partner-exhibit")}
        style={{ marginTop: "100px" }}
      >
        Start New Exhibit
      </button>
      {/* <DashboardTable exhibits={exhibits} /> */}
    </div>
  );
};

export default PartnerDash;
