import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, redirect } from "react-router-dom";
// import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";
import Dashboard from "./Views/Dashboard";
import Login from "./Views/Login";
import Exhibit from "./Views/Exhibit";
import Review from "./Views/Review";
import { collection, getDocs } from "firebase/firestore";
import { storage, db, auth } from "./firebase.config";
import Edit from "./Views/Edit";
import Navbar from "./Views/Navbar";
import PartnerDash from "./Views/PartnerDash";
import PartnerExhibit from "./Views/PartnerExhibit";
import PartnerReview from "./Views/PartnerReview";
import { slugify } from "./utils/helperFunctions";
import { useSelector } from "react-redux";

function App() {
  const [pdfExhibit, setPdfExhibit] = useState();
  const [imgExhibit, setImgExhibit] = useState();
  const exhibits = useSelector((state) => state.exhibits);
  const [reviewExhibitID, setReviewExhibitID] = useState();
  const [users, setUsers] = useState([]);

  const date = new Date();
  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear() - 2000;
  let currentDate = `${month}/${day}/${year}`;

  const loggedIn = auth.currentUser;

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");
      const userDocs = await getDocs(usersCollection);
      const usersArray = userDocs.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersArray);
    };

    fetchUsers();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <img
          class="office-logo"
          src="https://mlincsolutions.com/wp-content/uploads/2024/05/ComplyOffice-Logo.png"
        />
        {/* <h1>COMPLY OFFICE &trade;</h1> */}
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route
            path="/dashboard"
            element={<Dashboard setReviewExhibitID={setReviewExhibitID} />}
          ></Route>
          <Route path="/partner-dash" element={<PartnerDash />}></Route>
          <Route
            path="/exhibit"
            element={
              <Exhibit
                currentDate={currentDate}
                setPdfExhibit={setPdfExhibit}
                setImgExhibit={setImgExhibit}
                imgExhibit={imgExhibit}
                pdfExhibit={pdfExhibit}
                setReviewExhibitID={setReviewExhibitID}
              />
            }
          ></Route>
          {users.map((user) => {
            const url = slugify(user.companyName); // Assuming user object has name or email
            return (
              <Route
                key={user.id}
                path={`/${url}/partner-exhibit`}
                element={
                  <PartnerExhibit
                    currentDate={currentDate}
                    setReviewExhibitID={setReviewExhibitID}
                  />
                }
              />
            );
          })}
          <Route
            path="/partner-exhibit"
            element={
              <PartnerExhibit
                currentDate={currentDate}
                setPdfExhibit={setPdfExhibit}
                setImgExhibit={setImgExhibit}
                imgExhibit={imgExhibit}
                pdfExhibit={pdfExhibit}
                setReviewExhibitID={setReviewExhibitID}
              />
            }
          ></Route>
          <Route
            path="/review"
            element={
              <Review
                pdfExhibit={pdfExhibit}
                imgExhibit={imgExhibit}
                reviewExhibitID={reviewExhibitID}
              />
            }
          ></Route>
          <Route
            path="/partner-review"
            element={
              <PartnerReview
                pdfExhibit={pdfExhibit}
                imgExhibit={imgExhibit}
                reviewExhibitID={reviewExhibitID}
              />
            }
          ></Route>
          {!!exhibits &&
            exhibits?.length > 0 &&
            exhibits.map((exhibit) => {
              const url = `${slugify(exhibit.companyName)}-${slugify(
                exhibit.id
              )}`;
              return (
                <Route
                  key={exhibit.id}
                  path={`/review-${url}`}
                  element={
                    <Review
                      pdfExhibit={exhibit.pdfURL}
                      imgExhibit={exhibit.imageURL}
                      reviewExhibitID={reviewExhibitID}
                    />
                  }
                ></Route>
              );
            })}
          {!!exhibits &&
            exhibits?.length > 0 &&
            exhibits.map((exhibit) => {
              const url = `${slugify(exhibit.companyName)}-${slugify(
                exhibit.id
              )}`;
              console.log(`/edit-${url}`);
              return (
                <Route
                  key={exhibit.id}
                  path={`/edit-${url}`}
                  element={
                    <Edit
                      user={user}
                      exhibit={exhibit}
                      currentDate={currentDate}
                      setPdfExhibit={setPdfExhibit}
                      setImgExhibit={setImgExhibit}
                    />
                  }
                ></Route>
              );
            })}
        </Routes>
      </header>
    </div>
  );
}

export default App;
