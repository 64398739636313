import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import "./table.css";
import { slugify } from "../utils/helperFunctions";
import { useSelector } from "react-redux";
import { FaPencilAlt, FaTrashAlt, FaEye } from "react-icons/fa";

const DashboardTable = ({
  exhibits,
  setDeleteExhibitId,
  superUser,
  setModalToggle,
  setReviewExhibitID,
}) => {
  return (
    <div className="table-container" role="table">
      <div className="flex-table header" role="rowgroup">
        <div className="flex-row" role="columnheader">
          Created
        </div>
        <div className="flex-row first" role="columnheader">
          Exhibits
        </div>
        <div className="flex-row" role="columnheader">
          Partner
        </div>
        <div className="flex-row" role="columnheader">
          Status
        </div>
      </div>
      {exhibits?.map((exhibit, index) => {
        const url = `${slugify(exhibit.companyName)}-${slugify(exhibit.id)}`;
        return (
          <div
            key={index}
            className={
              index % 2 !== 0
                ? "flex-table row even-row"
                : "flex-table row odd-row"
            }
            role="rowgroup"
          >
            <div className="flex-row" role="cell">
              <div className="applicant-container">
                <p>{`${exhibit.createdDate}`}</p>
              </div>
            </div>

            <div className="flex-row first" role="cell">
              <div className="first-cell-container">
                <p className="table-exhibit-container">
                  {" "}
                  {`${exhibit.companyName} - ${
                    !!exhibit.signDate ? exhibit.signDate : "Not Signed"
                  }`}
                </p>
                <div className="table-btn-container">
                  <Link
                    to={`/review-${url}`}
                    className="posted-btn view-exhibit-button"
                    state={{ showSubmit: false }}
                    onClick={() => {
                      setReviewExhibitID(exhibit.id);
                    }}
                  >
                    <FaEye />
                  </Link>
                  {exhibit.status !== "Submitted" && (
                    <Link
                      to={`/edit-${url}`}
                      className="posted-btn edit-exhibit-button"
                      state={{ status: exhibit.status }}
                    >
                      <FaPencilAlt />
                    </Link>
                  )}
                  <Link
                    onClick={() => {
                      setModalToggle(true);
                      setDeleteExhibitId(exhibit.id);
                    }}
                    className="posted-btn remove-exhibit-button"
                  >
                    <FaTrashAlt />
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex-row" role="cell">
              <div className="applicant-container">
                <p>{`${exhibit.serviceProvider}`}</p>
              </div>
            </div>
            <div className="flex-row" role="cell">
              <div className="applicant-container">
                <p>{exhibit.status}</p>
              </div>
            </div>
          </div>
        );
      })}
      <div className="table-row-a"></div>
    </div>
  );
};

export default DashboardTable;
