import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { storage, db, logout, auth } from "../firebase.config";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { error } from "pdf-lib";
import DashboardTable from "../Components/DashboardTable";
import { Input } from "../Components/Input";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Components/Modal";
import { addExhibits, editExhibits } from "../Redux/Exhibits/exhibitSlice";
import { GrDocumentText } from "react-icons/gr";

const Dashboard = ({ setReviewExhibitID }) => {
  const navigate = useNavigate();
  const [pdfList, setPDFList] = useState([]);
  const pdfListRef = ref(storage, "pdfs/");
  const [deleteExhbitID, setDeleteExhibitId] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const exhibits = useSelector((state) => state.exhibits);

  useEffect(() => {
    console.log("USER", user);
    console.log(auth.currentUser);
    if (!auth.currentUser) navigate("/");
  }, []);

  async function getExhibits() {
    const exhibitsCollection = collection(db, "exhibits");
    await getDocs(exhibitsCollection)
      .then((response) => {
        const exhibitsArray = response.docs.map((doc) => ({
          ...doc.data(),
        }));

        const companyExhibitArray = exhibitsArray.filter(
          (exhibit) =>
            exhibit.companyName.trim().toLowerCase() ===
              user.companyName.trim().toLowerCase() &&
            exhibit.status !== "Partner"
        );

        dispatch(addExhibits(companyExhibitArray));
      })
      .catch((error) => console.log(error.message));
  }

  useEffect(() => {
    getExhibits();
  }, []);

  return (
    <>
      {modalToggle && (
        <Modal
          setModalToggle={setModalToggle}
          deleteExhbitID={deleteExhbitID}
        />
      )}
      <div className="dashboard">
        <div className="title-btn-wrap">
          <h2>
            {`Welcome `}
            <span className="company-name">{`${user.companyName}`}</span>
          </h2>
          {exhibits?.length < 1 && (
            <button
              className="dashboard-btn fresh new-exhibit-btn"
              type="button"
              onClick={() => navigate("/exhibit")}
            >
              Start A New Exhibit
              <GrDocumentText />
            </button>
          )}
          <button
            className="dashboard-btn add-partner-btn"
            onClick={async () => {
              await logout();
              navigate("/");
            }}
          >
            Log Out
          </button>
          {exhibits?.length > 0 && (
            <button
              className="dashboard-btn new-exhibit-btn"
              type="button"
              onClick={() => navigate("/exhibit")}
            >
              Start New Exhibit
            </button>
          )}
        </div>
        {exhibits?.length > 0 && (
          <DashboardTable
            setDeleteExhibitId={setDeleteExhibitId}
            setModalToggle={setModalToggle}
            exhibits={exhibits}
            setReviewExhibitID={setReviewExhibitID}
          />
        )}
      </div>
    </>
  );
};

export default Dashboard;
