import React, { useEffect, useState } from "react";
import "./confirmation.css";
import { useSelector } from "react-redux";

const DataModal = ({
  setErrorOpen,
  errorOpen,
  formState,
  setFormState,
  setOverrideState,
}) => {
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorIndex, setErrorIndex] = useState(0);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (errorOpen.length === 0) return;

    switch (errorOpen[errorIndex]) {
      case "sqftLeased":
        setErrorMessage1(
          `The average square feet leased is ${
            Math.round((formState.sqftLeased / formState.desks) * 100) / 100
          } which is very high. Please include only the square feet used to calculate your lease payment that is 100% dedicated to ${
            user.companyName
          } and do not include any square feet shared with ${
            formState.serviceProvider ? formState.serviceProvider : "the Lessor"
          } or with other tenants such as hallways, restrooms, kitchens, lobbies, meeting rooms, reception areas, etc.`
        );
        setErrorMessage2("Is your entry correct?");
        break;
      case "cam":
        setErrorMessage1(
          `We noticed that "Common Area Maintenance" is not checked on the form. Most desk rentals or office leases include this item in the lease payment.`
        );
        setErrorMessage2(
          `Will ${user.companyName} pay Common Area Maintenance separately and in addition to the lease payment? If not, click 'No' and select the checkbox to continue.`
        );
        break;
      case "tax":
        setErrorMessage1(
          `We noticed that "Property Insurance and Taxes" is not checked on the form. Most desk rentals or office leases include this item in the lease payment.`
        );
        setErrorMessage2(
          `Will ${user.companyName} pay Property Insurance and Taxes separately and in addition to the lease payment? If not, click 'No' and select the checkbox to continue.`
        );
        break;
      case "meeting":
        setErrorMessage1(
          `Is the furnished meeting/conference room shared with ${formState.serviceProvider} or with other tenants?`
        );
        setErrorMessage2(
          `If it is included in the SF leased, click 'No' and deselect the checkbox to continue.`
        );
        break;
      case "kitchen":
        setErrorMessage1(
          `Is the Kitchen shared with ${formState.serviceProvider} or with other tenants?`
        );
        setErrorMessage2(
          `If it is included in the SF leased, click 'No' and deselect the checkbox to continue.`
        );
        break;
      case "furnished":
        setErrorMessage1(
          `Is the furniture provided by ${formState.serviceProvider}?`
        );
        setErrorMessage2(
          `If it is provided by ${user.companyName}, click 'No' and deselect the checkbox to continue.`
        );
        break;
      case "copy":
        setErrorMessage1(
          `Is the copy/fax/print/scan machine provided by ${formState.serviceProvider}?`
        );
        setErrorMessage2(
          `If it is provided by ${user.companyName}, click 'No' and deselect the checkbox to continue.`
        );
        break;
      case "receptionist":
        setErrorMessage1(
          `Is the receptionist answering service provided by ${formState.serviceProvider}?`
        );
        setErrorMessage2(
          `If it is provided by ${user.companyName}, click 'No' and deselect the checkbox to continue.`
        );
        break;
      default:
        setErrorOpen(false);
        break;
    }
  }, [errorIndex, errorOpen, formState, setErrorOpen]);

  const handleNext = () => {
    if (errorIndex + 1 < errorOpen.length) {
      setErrorIndex(errorIndex + 1);
    } else {
      setErrorIndex(0);
      setErrorOpen(false);
    }
  };

  const handleNo = () => {
    if (errorIndex + 1 < errorOpen.length) {
      setErrorIndex(errorIndex + 1);
    } else {
      setErrorIndex(0);
      setErrorOpen(false);
    }
  };

  if (!errorOpen || errorOpen.length === 0) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Are you sure?</h2>
        <p className="modal-p">{errorMessage1}</p>
        <p>{errorMessage2}</p>
        <div className="modal-buttons">
          <button className="cancel-modal" onClick={handleNo}>
            No
          </button>

          <button
            className="delete-modal"
            onClick={() => {
              setOverrideState((prev) => ({
                ...prev,
                [errorOpen[errorIndex]]: true,
              }));
              handleNext();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataModal;
