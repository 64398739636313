import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../Redux/User/userSlice";
import exhibitReducer from "../Redux/Exhibits/exhibitSlice";

//Store
export const store = configureStore({
  reducer: {
    user: userReducer,
    exhibits: exhibitReducer,
  },
});
